@font-face {
  font-family: "Hylia";
  src: url("./assets/HyliaSerifBeta-Regular.otf");
  font-weight: 400;
  font-style: normal;
}

.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.MuiTooltip-tooltip {
  font-size: 1.875rem;
}


.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

* {
  scrollbar-color: #e0c039 transparent !important;
}

@-webkit-keyframes glow {
  0%   { color: #000; }
  100% { color: #e0c039; }
}
@-moz-keyframes glow {
  0%   { color: #000; }
  100% { color: #e0c039; }
}
@-o-keyframes glow {
  0%   { color: #000; }
  100% { color: #e0c039; }
}
@keyframes glow {
  0%   { color: #000; }
  100% { color: #e0c039; }
}

